<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import routerLoginMixin from '@/mixins/routerLoginMixin'

export default {
  mixins: [routerLoginMixin],
  computed: {
    ...mapGetters(['userInfo']),
    checkPaymentStatusUrl() {
      return process.env.VUE_APP_HOTSPOT_USER_MUST_REGISTER === 'false'
        ? 'unregistered-customer/check-payment-status'
        : 'customer/check-payment-status'
    },
  },
  mounted() {
    this.checkPaymentStatus()
  },
  methods: {
    checkPaymentStatus() {
      axios.post(this.checkPaymentStatusUrl, {
        isManualCheck: true,
        routerId: this.userInfo.routerId,
        macAddress: this.userInfo.mac_address,
      })
        .then(response => {
          const { data } = response

          if (data.status === 'active') {
            this.$toast.success("You'll be automatically logged in shortly.")
            this.loginToRouter(data.data.username, data.data.router_auth_password, this.userInfo.routerLoginLink)
          }
        })
    },

  },
}
</script>
